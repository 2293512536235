'use client';

import { ReactNode } from 'react';

import { ChatProvider } from '@/contexts/chat-context';
import { PatientProgramProvider } from '@/contexts/patient-program-context';

export const Providers = ({ children }: { children: ReactNode }) => {
   return (
      <PatientProgramProvider>
         <ChatProvider>{children}</ChatProvider>
      </PatientProgramProvider>
   );
};
